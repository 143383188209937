@import "~bootstrap/scss/bootstrap.scss";
#page {
  padding-top: 5.5em;
}
.set-language {
  height: 30px;
  padding: 0;
  margin: 0;
  div {
    padding: 0;
    margin: 0;
  }
  img {
    max-width: 50px;
    object-fit: contain;
    height: 30px;
  }
}
.download-link {
  padding-left: 28px;
  padding-top: 20px;
}
.pointer {
  cursor: pointer;
}
.border-1 {
  border: 1px solid #d0d0d0;
}
.navbar {
  background-color: #00162a;
  min-height: 2em;
  // @extend .bg-faded;
}
a.page-link {
  cursor: pointer;
}
a.nav-link.active {
  font-weight: 600;
}
a.page-link.disabled {
  color: #b0b0b0 !important;
  cursor: inherit;
}
a.page-link.disabled:hover {
  background-color: white;
  color: #b0b0b0;
}

.btn:hover {
  cursor: pointer;
}
.striped.odd {
  background-color: #f5f5f2;
}
.highlight:hover {
  background-color: #f2eded;
}
.quantity {
  width: 20px;
}
.basketcounter {
  padding: 0px 6px;
  padding-top: 3px;
  background-color: blue;
  border-radius: 100%;
  color: white;
  font-size: 12px;
  margin-top: -12px;
  margin-bottom: 12px;
}

.btn-xs {
  padding: 0.15rem 0.25rem;
  font-size: 0.675rem;
  border-radius: 0.1rem;
}
.ProseMirror {
  min-height: 200px;
  border-left: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
}
.ProseMirror-menubar {
  min-height: 10px !important;
}
.front-page-image2 {
  height: 400px;
  padding-top: 40px;
  padding-left: 300px;
  padding-right: 340px;
  margin-top: -30px;
  margin-left: -320px;
  margin-right: -320px;
  margin-bottom: 80px;
  color: white;
}
.blog-body img,
.ProseMirror img {
  max-width: 100%;
}
.checkbox-inline {
  margin-right: 15px;
}
.body {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
}
.checkout-delete {
  max-height: 35px;
}
.collapse.in {
  display: block;
}
.body {
  padding-top: 60px;
}
body {
  background: url(/image/bg-img2.jpg) 50% 50% no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  background-color: #000;
  font-family: "Roboto";
}
nav .nav-link.nav-link.nav-link {
  color: rgba(255, 255, 255, 0.8);
  padding-left: 20px;
  font-size: 18px;
}
.navbar-brand img {
  height: 35px;
}
.frontpage-columns h3 {
  color: #ff7f00;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 36px;
}
.frontpage-columns h5 {
  color: #ff7f00;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 25px;
}
h3 {
  color: #ff7f00;
  font-family: "Roboto Condensed", sans-serif;
}
.frontpage-columns p {
  text-shadow: 0 4px 24px rgba(0, 44, 84, 1);
}
.head-text {
  text-shadow: 0 4px 24px rgba(0, 44, 84, 1);
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (min-width: 500px) {
  .head-text {
    h5,
    p {
      width: 50%;
    }
  }
}
.frontpage-columns {
  padding-top: 100px;
}
.admin {
  background-color: #fff;
  color: #666;
  .admin-container {
    padding-top: 30px;
    padding-bottom: 300px;
  }
}
.btn-secondary {
  @extend .btn;
}
.btn-default {
  @extend .btn;
  @extend .btn-secondary;
}
.blog {
  padding-top: 50px;
}
h1 {
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
}
.product-card {
  background-color: #082e4e78;
  padding: 10px 0 10px 0;
  height: 70px;
}
.fixed-filter-bar {
  position: fixed;
  left: 35px;
  background-color: #082e4e78;
  bottom: 35px;
  top: 100px;
  padding: 20px;
  width: 300px;
}
.full-width-image {
  width: 100%;
  object-fit: contain;
  height: 100%;
  margin-bottom: 20px;
}
.media-list-input {
  align-items: start;
  margin: 5px;
  padding: 5px;
  background-color: #f2eded;
  .close {
    padding-left: 5px;
  }
}
.form-group-inline .form-group {
  display: flex;
}
